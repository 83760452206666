 export var ipServerObjet = [{
   ip: "https://bgrsmart.com/mafitness",
   sitewebLink:"https://mafitness.dz/produits",
   qrGeneratorLink:"https://bgrsmart.com/mafitness/bgrsmart/qrcode.php",
 }
];

/*

    ip: "https://sprintpark-dz.com/gym",
    sitewebLink:"https://sprintpark-dz.com/produits",
    qrGeneratorLink:"https://sprintpark-dz.com/gym/bgrsmart/qrcode.php",


     ip: "https://bgrsmart.com/mafitness",
     sitewebLink:"https://mafitness.dz/produits",
     qrGeneratorLink:"https://bgrsmart.com/mafitness/bgrsmart/qrcode.php",
*/

